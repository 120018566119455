<div id="backofficeId" class="container-fluid" style="background-color: whitesmoke;">
  <div class="row flex-nowrap">
    <!-- Sidebar -->
    <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0" style="background-color: #198754;">
      <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a routerLink="/home-usuario" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <img width="150" height="150" src="/assets/banners-home/logo_nota_white.png" alt="banner-home"/>
        </a>
        <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start text-white"
          id="menu">
          <li>
            <a class="nav-link px-0 align-middle">
              <i class="fs-4 bi-house text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" routerLink="/backoffice/home"
                style="cursor: pointer;">Home</span>
            </a>
          </li>
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" || tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações" || 
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a class="nav-link px-0 align-middle">
              <i class="fs-4 bi bi-bar-chart-steps text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" routerLink="backoffice/listar-solicitacoes"
                style="cursor: pointer;">Solicitações Entidade  </span>
            </a>
          </li>
        }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" || tokenService.userLogado.getValue().profile == "Super Admin" ){
          <li>
            <a href="#submenu4" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-gear text-white"> </i>
              <span class="ms-1 d-none d-sm-inline text-white">Parâmetros</span>
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu4" data-bs-parent="#menu">
              <li class="w-100">
                <a class="nav-link px-0" style="cursor: pointer;" routerLink="/backoffice/listar-sorteios">
                  <span class="d-none d-sm-inline text-white" style="margin-left: 10%;">Sorteios</span>
                </a>
              </li>
              <li class="w-100">
                <a class="nav-link px-0" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/listar-email"
                    style="margin-left: 10%;">Email</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/listar-rateios"
                    style="margin-left: 10%;">Rateios</span>
                </a>
              </li>
            </ul>
          </li>
        }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" || 
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu5" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-tools text-white"></i>
              <span class="ms-1 d-none d-sm-inline text-white">Manutenção</span>
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu5" data-bs-parent="#menu">
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/listar-manutencao-sorteios"
                    style="margin-left: 10%;">Sorteio</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/participantes-entidades-sociais"
                    style="margin-left: 10%;">Entidades</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/participantes-pessoa-fisica"
                    style="margin-left: 10%;">Pessoa Física</span>
                </a>
              </li>
            </ul>
          </li>
        }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" || 
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a class="nav-link px-0 align-middle" routerLink="/backoffice/rotinas">
              <i class="fs-4 bi-repeat text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" style="cursor: pointer;">Rotinas</span>
            </a>
          </li>
        }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" || tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações" || 
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu6" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-at text-white"></i>
              <span class="ms-1 d-none d-sm-inline text-white">Comunicação</span>
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu6" data-bs-parent="#menu">
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/enviar-email">Enviar Email</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/comunicacao/premios-pf">Premiar
                    Pessoa Física</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/comunicacao/premios-es">Premiar
                    Entidade</span>
                </a>
              </li>
            </ul>
          
          </li>
        }
        
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" || tokenService.userLogado.getValue().profile == "Gerenciamento de conteúdo" || 
          tokenService.userLogado.getValue().profile == "Super Admin" || tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações"){
          <li>
            <a class="nav-link px-0 align-middle" 
            (click)="openCmsInNewTab()" target="_blank">
              <i class="fs-4 bi bi-newspaper text-white" style="cursor: pointer;"></i> 
              <span class="ms-1 d-none d-sm-inline text-white" style="cursor: pointer;">Painel de Notícias</span>
            </a>
          </li>
        }
        @if ( tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu7" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-tools text-white"></i>
              <span class="ms-1 d-none d-sm-inline text-white" 
               routerLink="/backoffice/gerenciar-permissoes">Gerenciar Permissões</span>
            </a>
          </li>
        }
        </ul>
        <hr>
      </div>
    </div>
    <!-- Main content -->
    <div class="col py-3 router-content">
      <div class="d-flex justify-content-end dropdown pb-4">
        <a href="#" class="d-flex align-items-center text-black text-decoration-none dropdown-toggle" id="dropdownUser1"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span class="d-none d-sm-inline mx-1"><i class="bi bi-person-circle" style="margin-right: 5px;"></i>{{tokenService.userLogado.getValue().name}}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
          <li><a class="dropdown-item" routerLink="/home-usuario">Voltar para home</a></li>
        </ul>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>